import React from 'react'
import KakaoventuresLogo from '../../../assets/icon/kakaoventures.png'
import KbstockLogo from '../../../assets/icon/kbstock.png'
import ShinhanCardLogo from '../../../assets/icon/shinhancard.png'
import './MallFooter.scss'

export default function MallFooter({ type }) {
	return (
		<div className="mall-footer-container">
			{
				type === 'POINT'
					? <>
						<div className='footer_label'>유의사항</div>
						<ul>
							<li>본 예약상품권은 구매 후 ‘방문 일자‘와 ‘시간’ 등을 지정해 예약을 하고 매장을 방문해야 사용하실 수 있습니다.</li>
							<li>구매자와 예약자 그리고 사용자의 전화번호가 동일해야 사용 가능합니다.</li>
							<li>본 예약상품권의 남은 금액은 유효기한 내 예약상품권 사용이 가능한 매장에서 사용하실 수 있습니다.</li>
							<li>본 예약상품권은 제휴사 사정으로 변경 및 중단될 수 있습니다.</li>
							<li>예약상품권은 수요예측 AI 모델링을 기반으로 한 예약 서비스로, 매장 상황에 따라 예약이 가능한 시간대가 변동될 수 있습니다.</li>
						</ul>
						<div className='footer_label'>환불규정</div>
						<ul>
							<li>구매 후 14일 초과 시 환불이 불가합니다.</li>
							<li>해당 예약상품권의 사용 이력이 있는 경우 취소 및 현금으로 환불이 불가합니다.</li>
							<li>본 예약상품권은 환불 불가 규정에 동의하셔야 구매가 가능합니다.</li>
							<li>환불 및 관련 문의는 아래 테이블매니저 고객센터로 연락 바랍니다.</li>
						</ul>
						<div className='footer_label'>예약상품권 by 테이블매니저</div>
						<ul>
							<li>예약상품권은 더매니저(주)가 레스토랑 파트너와 함께 제공하는 서비스입니다. 수요예측 AI로 예약 가능한 좌석의 가격을 최적화하여 고객님들께 얼리버드 베네핏을 제공합니다.</li>
							<li>더매니저(주)는 AI 예약 X 마케팅 전문 기업입니다.<br />
								<img src={KakaoventuresLogo} className="logo kakao" alt="-" />, <img src={KbstockLogo} className="logo" alt="-" />, <img src={ShinhanCardLogo} className="logo last" alt="-" />, SJ투자파트너스, 메가인베스트먼트, 캡스톤파트너스 등이 투자했고, 수요예측 AI 기술 특허를 보유하고 있습니다.</li>
						</ul>
						<div className='footer_label'>테이블매니저 고객센터</div>
						<ul>
							<li>1644-9997</li>
							<li><a href='mailto:voucher@tablemanager.team?subject=[예약상품권 환불요청]&body=성함:%0D%0A연락처:%0D%0A구매 브랜드:%0D%0A권종(예: 5만원권,10만원권):%0D%0A구매 장 수:%0D%0A환불 장 수 (부분 취소 불가합니다.):%0D%0A환불 사유:'>voucher@tablemanager.team</a></li>
							<li>평일ㅣ10:00-19:00 (점심시간 12-13시 제외)<br />토, 일요일 및 공휴일 휴무</li>
						</ul>
					</>
					: <>
						<div className='footer_label'>유의사항</div>
						<ul>
							<li>본 예약상품권은 구매 후 ‘방문 일자‘와 ‘시간’ 등을 지정해 예약을 하고 매장을 방문해야 사용하실 수 있습니다.</li>
							<li>구매자와 예약자 그리고 사용자의 전화번호가 동일해야 사용 가능합니다.</li>
							<li>본 예약상품권은 제휴사 사정으로 변경 및 중단될 수 있습니다.</li>
						</ul>
						<div className='footer_label'>환불규정</div>
						<ul>
							<li>구매 후 14일 초과 시 환불이 불가합니다.</li>
							<li>해당 예약상품권의 사용 이력이 있는 경우 취소 및 현금으로 환불이 불가합니다.</li>
							<li>본 예약상품권은 환불 불가 규정에 동의하셔야 구매가 가능합니다.</li>
							<li>환불 및 관련 문의는 아래 테이블매니저 고객센터로 연락 바랍니다.</li>
						</ul>
						<div className='footer_label'>예약상품권 by 테이블매니저</div>
						<ul>
							<li>예약상품권은 더매니저(주)가 레스토랑 파트너와 함께 제공하는 서비스입니다. 수요예측 AI로 예약 가능한 좌석의 가격을 최적화하여 고객님들께 얼리버드 베네핏을 제공합니다.</li>
							<li>더매니저(주)는 AI 예약 X 마케팅 전문 기업입니다.<br />
								<img src={KakaoventuresLogo} className="logo kakao" alt="-" />, <img src={KbstockLogo} className="logo" alt="-" />, <img src={ShinhanCardLogo} className="logo last" alt="-" />, SJ투자파트너스, 메가인베스트먼트, 캡스톤파트너스 등이 투자했고, 수요예측 AI 기술 특허를 보유하고 있습니다.</li>
						</ul>
						<div className='footer_label'>테이블매니저 고객센터</div>
						<ul>
							<li>1644-9997</li>
							<li><a href='mailto:voucher@tablemanager.team?subject=[예약상품권 환불요청]&body=성함:%0D%0A연락처:%0D%0A구매 브랜드:%0D%0A권종(예: 5만원권,10만원권):%0D%0A구매 장 수:%0D%0A환불 장 수 (부분 취소 불가합니다.):%0D%0A환불 사유:'>voucher@tablemanager.team</a></li>
							<li>평일ㅣ10:00-19:00 (점심시간 12-13시 제외)<br />토, 일요일 및 공휴일 휴무</li>
						</ul></>
			}

		</div>
	)
}
