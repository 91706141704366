import React, { useEffect } from 'react'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'
import { useHistory } from 'react-router-dom'
import './ServiceTerms.scss'

export default function ServiceTerms(props) {
	const history = useHistory()
	const popstateEvent = (event) => {
		event.preventDefault()
		props.closeTerms()
	}

	const closeTerm = () => {
		history.goBack()
		props.closeTerms()
	}

	useEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return (() => {
			window.removeEventListener('popstate', popstateEvent)
		})
	}, [])

	return (
		<div className="service-container">
			<div className="header">
				<div>
				</div>
				<div className="close-title">
          서비스 이용약관
				</div>
				<div className="close-button">
					<Close onClick={closeTerm}/>
				</div>
			</div>
			<div class="pre-container">
				<div class="service-title">제 1 조 (목적)</div>
				<div class="service-body">
			본 약관은 더매니저(이하 “회사”) 가 운영하는 더예약(the yeyak) 서비스(이하 “서비스”)를 이용함에 있어 “회사”와 “이용자”간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
				</div>
				<div class="service-title">제 2 조 (용어의 정의)</div>
				<div class="service-body">
			본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
					<br /> 1. “서비스”라 함은 구현되는 단말기 (PC, 휴대형단말기 등의 각종 유무선장치를 포함)와 상관없이 “이용자”와 상품 또는 용역 등(이하 “상품 등”)을 제공하는 “매장”간 예약거래를
			중개하는 서비스를 의미합니다.
					<br /> 2. “이용자”란 “회사”의 “서비스”를 이용하기 위해 회원가입 후 “서비스” 아이디로 로그인 또는 “비회원” 자격으로 약관에 따라 “회사”와 이용계약을 체결하고 “매장”이 제공하는 “상품
			등”을 이용하기 위하여 “서비스”를 통해 예약신청을 하는 자를 의미합니다.
					<br /> 3. “매장”이라 함은 회사의 “서비스”를 이용하여 “이용자”에게 “상품 등”을 제공하는 사업자를 의미합니다.
					<br /> 4. “비회원”이라 함은 “서비스” 이용가입을 하지 않고 “회사”가 제공하는 “서비스”를 이용하는 자를 말합니다.
					<br /> 5. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 사항은 관계 법령에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.
					<br />
				</div>
				<div class="service-title">제 3 조 (약관의 게시와 개정)</div>
				<div class="service-body">
			1. “회사”는 본 약관의 내용을 “이용자”가 쉽게 알 수 있도록 “서비스” 홈페이지(이하 “홈페이지”) 또는 연결화면을 통하여 게시합니다.
					<br /> 2. “회사”는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
					<br /> 3. “회사”가 약관을 개정할 경우 개정내용, 개정사유 및 적용 일자를 명시하여 그 개정약관의 적용일자 7일 전부터 적용일자까지 “홈페이지”에 공지합니다. 다만, “이용자”에게 불리하게 약관
			내용이 변경되는 경우에는 개정약관의 적용일자 30일 전부터 적용일자까지 공지합니다.
					<br /> 4. “회사”가 전항에 따라 개정약관을 공지하면서 일정 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 “이용자”가 명시적으로 거부의
			의사표시를 하지 아니한 경우 개정약관에 동의한 것으로 봅니다.
					<br /> 5. “이용자”는 개정약관에 동의하지 않는 경우에는 개정 약관의 적용일 이전에 명시적인 거부의사를 표시하고 이용계약을 해지할 수 있습니다.
					<br />
				</div>
				<div class="service-title">제 4 조 (개별 서비스에 대한 약관 및 약관의 해석)</div>
				<div class="service-body">
			1. "회사"는 필요한 경우 특정 “서비스”에 관하여 적용될 사항(이하 "개별약관") 및 세부적인 내용(이하 "이용정책")을 정하여 운영할 수 있으며, 해당 내용은 “홈페이지” 등을 통해 공지합니다.
					<br /> 2. 특정 “서비스”에 대한 "개별약관"이 본 약관과 상충할 경우에는 "개별약관"이 우선하여 적용됩니다.
					<br /> 3. "이용자"은 항상 “개별약관”이나 “이용정책”의 내용에 변경이 있는지를 주시하여야 하며, 변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.
					<br />
				</div>
				<div class="service-title">제 5 조 (이용계약의 체결)</div>
				<div class="service-body">
			1. 이용계약은 “이용자”가 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 "서비스" 이용 신청을 하고, “회사”가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
					<br /> 2. “회사”는 “가입신청자”의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, “회사”는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할
			수 있습니다.
					<br /> 1) “가입신청자”가 이 약관에 의하여 이전에 “이용자”의 자격을 상실한 적이 있는 경우, 단 "회사"의 “이용자” 재가입 승낙을 얻은 경우에는 예외로 함
					<br /> 2) 실명이 아니거나 타인의 명의를 이용한 경우.
					<br /> 3) 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은 경우.
					<br /> 4) 부정한 용도로 “서비스”를 사용하고자 하는 경우.
					<br /> 5) 14세 미만 아동이 법정대리인(부모 등)의 동의 없이 신청하는 경우.
					<br /> 6) "회사"로부터 이용정지 및 제한을 당한 “이용자”가 그 이용정지 기간 중에 이용계약을 임의 해지하고 재 신청을 하는 경우.
					<br /> 7) "가입신청자"의 귀책사유로 인하여 승인이 불가능하거나 기타 “회사”가 정한 제반 사항을 위반하여 신청하는 경우.
					<br /> 8) "회사"의 정책에 적합하지 않는 "이용자"로 판단되는 경우나 "서비스" 제공이 곤란한 경우, "이용자"의 이용 목적이나 "서비스" 이용 방법이 "회사"의 재산권이나 영업권을 침해하거나
			침해할 우려가 있는 경우.
					<br /> 9) 기타 "회사"가 합리적인 판단에 의하여 필요하다고 인정하는 경우.
					<br /> 3. 제1항에 따른 신청에 있어 "회사"는 본인인증을 요청할 수 있습니다.
					<br /> 4. 제 1항에 따른 신청에 있어 "회사"는 제공하는 "서비스"의 종류에 따라 필요한 경우 "가입신청자"에게 추가 정보를 요청할 수 있습니다.
					<br /> 5. 회사는 "서비스" 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
					<br /> 6. 본 조 제2항과 제5항에 따라 “가입신청자”의 가입신청의 승낙을 하지 아니하거나 유보한 경우, “회사”는 원칙적으로 이를 “가입신청자”에게 알리도록 합니다.
					<br /> 7. 이용계약의 성립 시기는 “회사”가 가입완료를 신청절차 상에서 표시하거나 "회사"가 정하는 방법으로 "회사"가 "가입신청자"에게 가입완료 안내를 발송하여 도달한 시점으로 합니다.
					<br />
				</div>
				<div class="service-title">제 6 조 (회원정보 등의 변경)</div>
				<div class="service-body">
			1. “이용자”는 “서비스”의 회원정보 관리화면을 통하여 언제든지 본인의 회원정보를 열람할 수 있으며, 이를 수정할 수 있습니다.
					<br /> 2. “이용자”는 회원가입신청시 기재한 사항이 변경되었을 경우, 회원정보 관리화면을 통하여 수정을 하여 “회사”에 알려야 합니다.
					<br /> 3. “이용자”가 제 2항을 위반하여 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대해서 “회사”는 책임지지 않습니다.
					<br />
				</div>
				<div class="service-title">제 7 조 (이용계약의 해지 및 이용제한)</div>
				<div class="service-body">
			1. "이용자"는 언제든지 "회사"가 정한 절차에 따라 “이용자”가 탈퇴를 요청하여 이용계약 해지 신청을 할 수 있으며, "회사"는 관련법 등이 정하는 바에 따라 이를 즉시 처리합니다.
					<br /> 2. "이용자"가 계약을 해지할 경우, “이용자”의 정보는 관련법 및 개인정보취급방침에 따라 처리됩니다.
					<br /> 3. “회사”는 아래 각 호의 사유가 발생하는 경우 “이용자”의 "서비스" 이용을 중단하거나 경고, 일시 정지, 영구이용정지 등으로 "서비스"이용을 단계적으로 제한할 수 있습니다.
					<br /> 1) 타인의 계정을 사용하여 "서비스"를 이용한 경우
					<br /> 2) "이용자"가 "회사"의 “서비스” 운영을 고의 및 과실로 방해하는 경우
					<br /> 3) 제5조 제2항에 따른 승낙거부 사유가 있음이 확인된 경우.
					<br /> 4) "서비스"의 대금결제, 기타 "서비스"이용과 관련하여 “이용자”가 부담하는 채무를 기일에 이행하지 않는 경우.
					<br /> 5) 타인의 "서비스" 이용을 방해하거나 정보를 도용, “매장”의 사업을 방해할 목적으로 반복적으로 구매의사 없이 예약을 남발하는 등 전자상거래질서를 위협하는 경우.
					<br /> 6) "회사"를 이용하여 법령과 이 약관이 금지하거나 공공질서, 미풍양속에 반하는 행위를 하는 경우.
					<br /> 7) "이용자"가 1년 동안 일정 횟수 이상 "서비스"를 이용하여 예약한 후 예약 취소 절차 없이 “매장”에 방문 하지 않은 경우 (No Show 하는 경우).
					<br /> 8) 기타 “회사”가 “이용자”의 이용정지를 진행할 합리적인 사유가 있는 경우.
					<br /> 4. "회사"는 제 3항에 따른 귀책사유로 "이용자"의 "서비스" 이용을 중단 및 제한하는 경우 이메일, 전화 또는 기타의 방법을 통하여 해지의사를 통지하며, 해지의사가 통지된 시점에
			이용계약은 종료됩니다.
					<br /> 5. 이용계약이 종료되는 경우 "이용자"의 예약정보는 소멸하며, “예약보증금” 환불 등의 처리는 "회사"의 환불규정에 의합니다.
					<br /> 6. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 “이용자”가 책임을 부담하며, "회사"는 일체의 책임을 부담하지 않습니다.
					<br /> 7. "회사"는 "이용자"가 계속해서 12개월 이상 로그인하지 않는 경우, “이용자” 정보의 보호 및 운영의 효율성을 위해 이용을 정지할 수 있습니다.
					<br /> 8. "이용자"는 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있으며, 이의가 정당하다고 "회사"가 인정하는 경우 "회사"는 즉시 "서비스"의 이용을
			재개합니다.
					<br />
				</div>
				<div class="service-title">제 8 조 (“서비스”의 내용 및 제공)</div>
				<div class="service-body">
			1. “회사”가 본 약관에 따라 “이용자”에게 제공하는 "서비스"는 아래 각 호와 같습니다. 단, “회사”는 일부 “서비스”의 이용조건 및 이용범위 등을 별도로 정하여 운영할 수 있으며 해당 사항은
			"이용자"에게 공지합니다.
					<br /> 1) 예약을 위한 “매장”의 정보 제공
					<br /> 2) "이용자"의 모든 예약 내역을 제공
					<br /> 3) "이용자"가 예약 신청시 “매장”에 예약 확정 제공
					<br /> 4) 일부 예약에 한하여 “예약보증금" 제도 운영
					<br /> 5) 기타 "회사"가 추가 개발하거나 다른 회사와의 제휴계〮약 등을 통해 "이용자"에게 제공하는 일체의 “서비스”
					<br /> 2. "회사"는 "서비스"를 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 다만, “회사”의 기술상 또는 업무상의 장애로 인하여 “서비스”를 즉시 개시하지 못하는 경우 “회사”는 제
			18조에 따른 방법으로 “이용자”에게 통지합니다.
					<br /> 3. "회사"는 "서비스"의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 "홈페이지"에서 공지한 바에 따릅니다. 정기점검시간은 사전에 공지하는 것을 원칙으로 하나 부득이 한
			사유가 있는 경우 사후에 통지할 수 있습니다.
					<br /> 4. "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제
			19조에 따라 "이용자"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이 한 사유가 있는 경우 사후에 통지할 수 있습니다.
					<br /> 5. "회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.
					<br /> 6. "회사"는 무료로 제공되는 “서비스”의 일부 또는 전부를 “회사”의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "이용자"에게
			별도의 보상을 하지 않습니다.
					<br />
				</div>
				<div class="service-title">제 9 조 (예약 정책)</div>
				<div class="service-body">
			1. "회사"는 "이용자"가 예약을 요청할 때, "매장"에 "이용자"의 개인정보 및 예약정보를 전달하고 "매장"의 응답에 무관하게 "이용자"에게 예약 결과를 전달하는 중개자 역할을 합니다.
					<br /> 2. "이용자"가 요청한 예약은 "매장" 상황에 따라 취소 또는 변경될 수 있으며, "회사"에서 결정한 방식에 따라 "이용자"에게 그 내용을 공지합니다. 단, "매장"에서 "이용자"에게
			개별적으로 연락을 취한 경우에 대해서는 별도로 공지하지 않습니다.
					<br /> 3. "이용자"의 휴대전화번호의 변경 등 "이용자"가 "서비스" 가입시에 입력한 전화번호와 예약시 사용중인 전화번호가 일치하지 않을 경우에는 예약이 불가하거나 자동적으로 취소될 수 있습니다.
					<br /> 4. "매장"은 일부 예약에 한하여 "예약보증금" 제도를 운영할 수 있으며, 이러한 경우 "이용자"가 예약을 요청할 때 정해진 금액의 “예약보증금”을 결제 또는 지급할 것을 요구할 수
			있습니다.
					<br /> 5. 예약과 관련하여 “이용자”가 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 “이용자”가 부담하여야 합니다.
					<br /> 6. "이용자"가 다음 각 사항들을 지키지 않을 시 불이익이 발생할 수 있으며 이에 대한 모든 책임은 “이용자”에게 있습니다.
					<br /> 1) "이용자"가 예약 신청 시 선택한 인원 수에 맞춰 예약된 "매장"에 방문
					<br /> 2) "이용자"가 예약 신청 시 선택한 날짜에 맞춰 예약된 "매장"에 방문
					<br /> 3) "이용자"가 예약 신청 시 선택한 시간에 맞춰 예약된 "매장"에 방문
					<br />
				</div>
				<div class="service-title">제 9 조의 1 (예약보증금 제도의 운영)</div>
				<div class="service-body">
			"매장"은 제 9조 제 4항과 같이 일부 예약에 한하여 "예약보증금" 제도를 운영할 수 있으며, 아래의 약관에 따라 운영됩니다.
					<br /> 1. “이용자"가 “서비스”를 통해 예약 접수 시 정해진 금액의 "예약보증금"을 결제하도록 요구
					<br /> 2. "이용자"는 제 10조에 따라 정해진 금액의 "예약보증금"을 결제
					<br /> 3. “이용자”가 “예약보증금”에 대한 결제를 완료한 경우 해당 예약이 확정
					<br /> 4. 예약 당일 "이용자"의 "매장" 방문이 확인된 경우, “매장”의 운영정책에 따라 “예약보증금” 환불이 가능할 수 있음
					<br /> 5. “예약보증금" 결제 후 확정된 “예약”을 취소하거나 “매장"에 방문하지 않을 시 일정 금액의 수수료 부과
					<br /> 1) "예약보증금" 환불 시에는 최소 3~10일이 소요되며, 결제수단에 따라 상이할 수 있습니다.
					<br /> 2) 별도의 수수료 규정이 명시되어 있지 않은 경우에는 아래의 정책을 따릅니다.
					<br /> - 방문 예정일 기준 6일 전까지 예약 취소 시 : 취소 수수료 없음
					<br /> - 방문 예정일 기준 5일 전 예약 취소 시 : "예약보증금"의 10% 취소 수수료 발생
					<br /> - 방문 예정일 기준 4일 전 예약 취소 시 : "예약보증금"의 30% 취소 수수료 발생
					<br /> - 방문 예정일 기준 3일 전 예약 취소 시 : "예약보증금"의 50% 취소 수수료 발생
					<br /> - 방문 예정일 기준 2일 전 예약 취소 시 : "예약보증금"의 70% 취소 수수료 발생
					<br /> - 방문 예정일 기준 1일 전 예약 취소 시 : “예약보증금”의 90% 취소 수수료 발생
					<br /> - 방문 예정일 당일 00시부터는 예약 취소가 불가능하며, 그 이후에 예약을 취소하거나 예약 당일 "매장"에 방문하지 않은 경우 "예약보증금"의 환불이 불가합니다.
					<br /> -방문 예정일 당일 “매장”에 어떠한 연락 없이 20분 이상의 방문 시간이 지연될 경우 예약은 자동으로 취소되며 "예약보증금"의 환불이 불가합니다.
					<br />
				</div>
				<div class="service-title">제 9 조의 2 (예약변경 및 취소)</div>
				<div class="service-body">
			1. 예약 변경 및 취소는 예약한 “매장”을 통해서만 가능합니다.
					<br /> 2. 예약 변경은 “매장”의 상황에 따라 예약 변경이 불가능 할 수도 있습니다.
					<br />
				</div>
				<div class="service-title">제 10 조 (사용 가능한 결제수단)</div>
				<div class="service-body">
			1. "이용자"는 "회사"의 “서비스를 통해 “매장”에서 구매한 “상품 등”에 대한 대금지급을 다음 각 호의 하나로 할 수 있습니다. (일부의 경우 다수를 결합하여 결제 가능)
					<br /> 1) 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
					<br /> 2) "이용자"가 “예약보증금”의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 "이용자"가 부담합니다.
					<br />
				</div>
				<div class="service-title">제 11 조 (정보의 제공 및 광고의 게재)</div>
				<div class="service-body">
			1. "회사"는 "이용자"가 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, SMS, 푸쉬 메시지, 카카오톡 채널 메시지 등의 방법으로 "이용자"에게 제공할 수 있습니다.
			다만, "이용자"는 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변을 제외하고는 언제든지 전자우편, SMS, 푸쉬 메시지, 카카오톡 채널 매시지 등에 대해서 수신 거절을 할 수 있습니다.
					<br /> 2. "회사"는 "서비스"의 운영과 관련하여 “서비스” 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "이용자"는 수신거절을 "회사"에게 할 수
			있습니다.
					<br /> 3. "이용자" 및 "비회원"은 “회사”가 제공하는 “서비스”와 관련하여 예약, 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
					<br /> 4. "회사"는 "이용자"가 “매장” 예약 시 “매장”과 “이용자” 간의 소통을 원활하게 하기 위하여 “이용자”가 예약하는 “매장”에 이름 및 전화번호 등의 기본정보를 제공하며, 이는 추후
			“매장”에 의해 예약 관련 정보 또는 할인 프로모션 정보 등을 SMS로 발송하는데 사용될 수 있습니다.
					<br /> 5. “회사”는 “이용자”의 사전동의를 받아 이용내역, 휴대전화번호 등을 활용한 맞춤 광고를 제공합니다. 이에 대한 자세한 사항은 “개인정보처리방침”을 참고하시기 바랍니다.
					<br />
				</div>
				<div class="service-title">제 12 조 (저작권의 귀속)</div>
				<div class="service-body">
			1. "회사"가 작성한 저작물에 대한 저작권 및 기타 지적 재산권은 "회사"에 귀속됩니다.
					<br /> 2. "이용자" 및 "비회원"은 "사이트"에 게재된 "정보" 중 "회사"에 지적 재산권이 귀속된 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의하여 영리
			또는 비영리 목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
					<br />
				</div>
				<div class="service-title">제 13 조 (회사의 책임과 의무)</div>
				<div class="service-body">
			1. "회사"는 관련 법령과 이 약관이 금지하거나 공공질서, 미풍양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다합니다.
					<br /> 2. "회사"는 "이용자"가 안전하게 "서비스"를 이용할 수 있도록 "이용자"의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
					<br /> 3. "회사"는 전자금융거래가 안전하게 처리될 수 있도록 선량한 관리자로서의 주의를 다하며, 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를
			위한 인력, 시설, 전자적 장치 등의 정보기술 부분 및 전자금융업무에 관하여 금융감독위원회가 정하는 기준을 준수합니다.
					<br /> 4. "회사"는 "서비스" 이용과 관련하여 발생하는 "이용자"의 불만 및 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비하며 "회사"는 "서비스" 이용과 관련하여
			"이용자"로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "이용자"이 제기한 의견이나 불만사항에 대해서는 전자우편 등을 통하여 "이용자"에게 처리과정 및 결과를
			전달합니다.
					<br /> 5. "회사"는 "이용자"로부터의 거래지시가 있음에도 불구하고 천재지변, "회사"의 귀책사유가 없는 정전, 화재, 통신장애 기타의 불가항력의 사유로 처리 불가능하거나 지연된 경우에는
			"이용자"에 대하여 이로 인한 책임을 지지 아니합니다.
					<br /> 6. “회사”는 예약 중개만을 합니다. 음식의 품질과 “매장”의 서비스에 관련하여 환불이나 보상은, 해당 “매장”에 책임이 있음을 알려드립니다.
					<br />
				</div>
				<div class="service-title">제 14 조 (이용자의 의무)</div>
				<div class="service-body">
			1. "이용자"는 "서비스" 이용과 관련하여 다음 각 호의 행위를 해서는 안됩니다.
					<br /> 1) "서비스"의 정상적인 운영 및 "회사"의 영업을 방해하는 행위
					<br /> 2) 본 약관을 위반하여 “매장” 및 제3자에게 손해를 끼치는 행위
					<br /> 3) 개인정보의 등록 또는 변경 시 허위 내용을 등록하거나 타인의 정보를 도용하는 행위
					<br /> 4) "회사"가 게시한 정보를 변경하는 행위
					<br /> 5) "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
					<br /> 6) "서비스" 및 제 3자의 저작권 등 지적 재산권을 침해하는 행위
					<br /> 7) "서비스" 및 제 3자의 명예를 손상하거나 업무를 방해하는 행위
					<br /> 8) 사실관계를 왜곡하는 정보를 제공하는 행위
					<br /> 9) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위
					<br /> 10) “회사”의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위
					<br /> 11) “매장” 정보, 사진 등 "서비스"에 게시된 게시물을 "회사"의 동의 없이 변형하거나 영리 또는 비영리 용도로 사용하는 행위
					<br /> 12) 기타 본 약관 및 관련 법령을 위반하는 행위
					<br /> 2. "이용자"는 관계법, 본 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는
			안 됩니다.
					<br /> 3. “이용자”는 아이디 및 비밀번호에 대한 관리 책임이 있으며, “서비스” 이용상의 과실 또는 제3자에 대한 부정 사용 등으로 인하여 발생하는 모든 문제에 대해 책임을 집니다.
					<br />
				</div>
				<div class="service-title">제 15 조 (개인정보보호)</div>
				<div class="service-body">
			1. "회사"는 "이용자"가 안전하게 "서비스"를 이용할 수 있도록 "이용자"의 개인정보보호를 위하여 개인정보보호정책을 실시하며 이에 따라 "이용자"의 개인정보보호를 최대한 보호하기 위하여 노력합니다.
					<br /> 2. "회사"의 개인정보보호정책은 "회사"의 "홈페이지"를 통해 확인할 수 있습니다. 단, "회사"의 공식 “홈페이지” 이외의 링크된 사이트에서는 “회사”의 개인정보취급방침이 적용되지
			않습니다.
					<br /> 3. "이용자"가 "회사"에 제공한 개인정보는 “서비스” 이용의 혼선 방지, 불법적 사용자에 대한 수사기관 수사협조, 기타 안정적인 “서비스” 제공을 위해 “이용자”가 탈퇴 후에도 일부
			보유하게 됩니다.
					<br /> 4. "회사"는 "회사"가 "서비스"를 중단하거나 "이용자"가 개인정보 제공 동의를 철회한 경우에는 신속하게 "이용자"의 개인정보를 파기합니다. 단, 전자상거래 등에서의 소비자 보호에 관한
			법률 등 관련 법률에서 정하는 바에 따라 일정 정보는 보관할 수 있습니다.
					<br /> 5. "회사"는 "이용자"의 동의 하에 관계 법령에서 정하는 바에 따라 추가적인 개인정보를 수집할 수 있습니다.
					<br /> 6. "회사"는 "이용자" 또는 “매장”의 귀책사유로 인하여 노출된 "이용자"의 개인정보에 대해서 일체의 책임을 지지 않습니다.
					<br /> 7. "회사"는 "이용자"가 제공한 정보를 "회사"의 "서비스" 운영을 위한 목적 이외의 용도로 사용하거나 "이용자"의 동의 없이 제3자에게 제공하지 않습니다. 단, 다음 각 호와 같은
			경우에는 예외로 합니다.
					<br /> 1) 이벤트에 참여한 “이용자”의 최소한의 정보가 해당 이벤트의 주최자에게 제공되는 경우
					<br /> 2) "매장" 예약시 "매장"과 "이용자"간의 효율적인 소통을 위하여 해당 "매장"에 "이용자"의 최소한의 정보가 제공하는 경우
					<br /> 3) 법령에 근거하여 “이용자”의 정보의 이용과 제3자에 대한 정보제공이 허용되는 경우
					<br /> 4) 마케팅, 홍보, 판촉 자료 등에 필요한 “이용자”의 정보(성별, 나이대, 관심음식, 관심지역, 매장 재방문율 등)를 제휴업체에 제공하는 경우
					<br /> 5) 기타 "회사"의 약관 및 정책에 따라 "이용자"의 동의를 구한 경우
					<br />
				</div>
				<div class="service-title">제 16 조 (이용자의 탈퇴 및 자격상실)</div>
				<div class="service-body">
			1. "이용자"는 언제든지 서면, 이메일, 전화, 기타 “회사”가 정하는 방법으로 “이용자”의 탈퇴를 요청할 수 있으며, 본 약관에 따라 “이용자”의 탈퇴가 제한되는 등의 특별한 사유가 없는 한 “회사”는
			“이용자”의 요청에 따라 조속히 “이용자”의 탈퇴에 필요한 제반 절차를 수행합니다.
					<br /> 2. "이용자"가 다음 각 호에 해당하는 경우, “회사”는 당해 “이용자”에 대한 일방 통보로써 “이용자”의 자격을 상실시킬 수 있습니다.
					<br /> 1) “이용자”의 가입 신청 시, 허위 내용을 등록하거나 타인의 명의를 도용 한 경우
					<br /> 2) 다른 “이용자”의 "서비스" 및 “매장”의 “서비스” 이용을 부당하게 방해하거나 그 정보를 도용하는 등 관련 법령을 위반하여 전자거래질서를 위협하는 경우
					<br /> 3) "회사"의 "홈페이지"를 이용하여 법령 및 이 약관에서 금지하거나 공서 양속에 반하는 행위를 하는 경우
					<br /> 4) 기타 본 약관이 규정하는 “이용자”의 의무를 위반하는 경우
					<br /> 3. 전항에 따라 “이용자” 자격이 상실된 “이용자”는 “회사”로부터 해당 사유발생에 대한 통지를 받은 날로부터 최대 30일 이내에 본인의 의견을 소명할 수 있으며, “회사”는 자격이 상실된
			“이용자”의 소명 내용을 심사하여 그 주장이 타당하다고 판단하는 경우 “이용자”로 하여금 계속하여 정상적인 “서비스”를 이용할 수 있도록 합니다.
					<br /> 4. "이용자"가 탈퇴를 하거나 “이용자”의 자격을 상실하는 경우, 이벤트 부정 이용 방지 등의 이유로 인해 재가입이 제한될 수 있습니다
					<br />
				</div>
				<div class="service-title">제 17 조 (서비스 종료)</div>
				<div class="service-body">
			1. "회사"가 "서비스" 제공을 종료하고자 할 경우, “서비스”를 종료하고자 하는 날로부터 3개월 이전에 본 약관 제 18조에 규정된 통지방법을 준용하여 “이용자”에게 알려드립니다.
					<br /> 2. “서비스” 종료일 이후 "이용자"는 예약 및 기타 “서비스”의 혜택을 받지 못합니다.
					<br />
				</div>
				<div class="service-title">제 18 조 (이용자에 대한 통지)</div>
				<div class="service-body">
			1. "회사"가 "이용자"에 대한 통지를 하는 경우 이 약관에 별도의 규정이 없는 한 전자우편 또는 전화 등으로 할 수 있습니다.
					<br /> 2. "회사"는 "이용자" 전체에 대한 통지의 경우 7일 이상 “서비스” 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
					<br />
				</div>
				<div class="service-title">제 19 조 (손해배상)</div>
				<div class="service-body">
			"회사"와 "이용자"는 본 약관을 위반함으로써 발생한 모든 책임을 각자가 부담하며 이로 인하여 상대방에게 손해를 입힌 경우에는 배상하여야 합니다.
					<br />
				</div>
				<div class="service-title">제 20 조 (회사의 책임제한)</div>
				<div class="service-body">
			1. "회사"는 천재지변, 디도스(DDOS), 클라우드 서버 장애(AWS), 기간통신사업자의 회선 장애 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한
			책임이 면제됩니다.
					<br /> 2. "회사"는 "이용자"의 귀책사유로 인한 “서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.
					<br /> 3. "회사"는 "이용자"가 "서비스"를 통한 상품의 구매 등과 관련하여 "매장"의 행위로 인해 "이용자"에게 발생한 손해에 대하여는 책임을 지지 않습니다.
					<br /> 4. "회사"는 "매장" 또는 "이용자"가 "서비스"와 관련하여 임의로 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
					<br /> 5. "회사"는 "이용자" 간 또는 "이용자"와 제3자 상호간에 "서비스"를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
					<br /> 6. "회사"는 무료로 제공되는 “서비스” 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
					<br />
				</div>
				<div class="service-title">제 21 조 (양도금지 등)</div>
				<div class="service-body">
			"이용자"는 "서비스"와 관련된 권리나 의무를 “회사”의 명시적인 사전 동의 없이 제3자에게 양도하거나 담보의 목적으로 제공할 수 없습니다.
					<br />
				</div>
				<div class="service-title">제 22 조 (분쟁조정)</div>
				<div class="service-body">
			1. "회사"는 "이용자"로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거쳐 처리합니다. 단, 처리 시 일정 기간이 소요될 경우 "이용자"에게 그 사유와 처리
			일정을 즉시 통보 합니다.
					<br /> 2. "이용자"는 "회사"의 분쟁처리결과에 대하여 이의가 있을 경우 '금융위원회의 설치 등에 관한 법률'에 따른 금융감독원의 금융분쟁조정위원회나 '소비자기본법' 에 따른 한국소비자원의
			소비자분쟁조정위원회, 전자거래기본법 제32조 및 동 시행령 제20조에 의하여 설치된 전자거래분쟁조정위원회에 회사의 전자금융거래서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.
					<br />
				</div>
				<div class="service-title">제 23 조 (준거법 및 재판관할)</div>
				<div class="service-body">
			1. 이 약관과 관련된 사항에 대하여는 대한민국 법을 준거법으로 합니다.
					<br /> 2. "회사"와 "이용자"간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.
					<br />
				</div>
				<div class="service-title">제 24 조 (인수 및 합병)</div>
				<div class="service-body">
			1. "회사"는 인수 또는 합병이 되는 경우, 보유하고 있는 모든 지적 재산권을 포함하여 “이용자”의 개인정보 또한 인수자에게 이관하며, 개인정보 이전에 관해서는 개별통지 합니다.
					<br /> 2. 인수자는 "서비스"의 “이용자”에게 기존 “이용자”의 자격 유지 또는 탈퇴 기회를 제공합니다.
					<br /> 3. 인수자는 개인정보를 제3자와 “이용자”의 동의 없이 사용할 수 없습니다.
					<br />
				</div>
				<div class="service-title">제 25 조 (회사의 연락처)</div>
				<div class="service-body">
			“회사”의 상호 및 주소 등은 다음과 같습니다.
					<br /> 상 호 : 더매니저㈜
					<br /> 대 표 자 : 안병준
					<br /> 주 소 : 서울특별시 강남구 논현로153길 53, 602호(신사동)
					<br /> 대표전화 : (02)1544-8262
					<br /> 부칙
					<br /> 이 약관은 2021년 4월 7일부터 시행합니다.
					<br /> 제정 및 개정 현황
					<br /> 2021.04.07 약관 제정
					<br /> 2021.04.07 약관 시행
					<br /> 2021.11.04 약관 수정
					<br /> 2021.11.12 약관 시행
				</div>
			</div>
		</div>

	)
}
