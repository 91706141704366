import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import BusinessInfo from '../../Share/BusinessInfo/BusinessInfo'
import Telephone from '../../Share/Telephone/Telephone'
import Seperator from '../../Share/Seperator'
import { inquiryType } from '../../../Components/Voucher/utils'
import { useVoucherContext } from '../../../context/Voucher/voucher'
import './Footer.scss'
import VoucherEmail from '../../Share/VoucherEmail/VoucherEmail'
import { useFetchPolicies } from '../../../hooks'
import ModalPortal from '../../Share/Modal/ModalPortal'
import PolicyModal from '../../Share/Modal/PolicyModal'

const footerSeperatorStyle = {
	width: '328px',
	height: '0.5px',
	margin: '0 0 16px 0',
	backgroundColor: '#d9dce4',
	border: '0'
}

export default function Footer() {
	const requiredPolicies = ['SERVICE', 'PRIVACY']

	const [openBusiness, setOpenBusiness] = useState(false)
	const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies })
	const history = useHistory()
	const { referralConfig } = useVoucherContext()

	const openPolicy = (type, setFunction, state) => {
		history.push(`${history.location.pathname}#${type}`)
		setFunction(state)
	}

	const showServiceTerm = (type) => {
		window.history.pushState(null, null, '')
		openServiceTerm(type)
	}

	const moveInquiryPage = (type) => {
		if (type === 'partnership')
			history.push('/voucher/inquiry/partnership')

		if (type === 'bulkpurchase')
			history.push('/voucher/inquiry/bulkpurchase')

		if (type === 'live-commerce')
			history.push('/voucher/inquiry/live-commerce')

		if (type === 'introduce')
			history.push('/voucher/main')

		if (type === 'theyeyakBlog')
			window.open('https://blog.naver.com/mytable_bytm', '_blank')
	}

	return (
		<footer className="footer-wrapper">
			{
				referralConfig.businessFooter ?
					<ul className="footer-business-menus">
						<li className="footer-menu-title">For Business</li>
						<li className="footer-menu" onClick={(() => moveInquiryPage(inquiryType.BULKPURCHASE))}>
							<span>대량구매 문의</span>
						</li>
						<li className="footer-menu" onClick={(() => moveInquiryPage(inquiryType.PARTNERSHIP))}>
							<span>제휴 및 입점 문의</span>
						</li>
						<li className="footer-menu" onClick={(() => moveInquiryPage(inquiryType.LIVE_COMMERCE))}>
							<span>라이브 커머스 문의</span>
						</li>
					</ul> :
					null
			}

			{
				referralConfig.blog ?
					<ul className="footer-introduce-menus">
						<li className="footer-menu-title">더예약</li>
						<li className="footer-menu" onClick={(() => moveInquiryPage(inquiryType.THEYEYAK_BLOG))}>
							<span>블로그 구경하기</span>
						</li>
					</ul> :
					null
			}

			<div className="footer-service-center">
				<div className="footer-service-center-header">
					<span className="footer-menu-title">고객센터</span>
					<span className="footer-service-center-sales-time">오전 10시 ~ 오후 7시 (주말,공휴일 제외)</span>
				</div>
				<Telephone></Telephone>
				<VoucherEmail></VoucherEmail>
			</div>

			<Seperator type="inline" style={footerSeperatorStyle}></Seperator>

			<div className="terms-wrapper">
				<ul className="terms-items">
					{!loading && requiredPolicies.map(policyType => {
						const policy = servicePolicies.find(item => item.type === policyType)
						return (
							<li className="terms-item" key={policy.id} onClick={() => showServiceTerm(policy.type)}>{policy.content.KO.name}</li>
						)
					})}
					<li className="terms-item" onClick={() => openPolicy('business', setOpenBusiness, true)}>사업자정보확인</li>
				</ul>
			</div>

			<div className="information-wrapper">
				<span className="information-company">더매니저(주)</span>
				<p className="information-notice">더매니저 주식회사는 통신판매중개자로서 더예약의 거래당사자가 아니며,입점판매자가 등록한 상품 및 거래에 대해 책임을 지지 않습니다.</p>
			</div>
			{openBusiness
				? <BusinessInfo closeTerms={() => setOpenBusiness(false)}></BusinessInfo>
				: null}
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm} />
			</ModalPortal>
		</footer>
	)
}
