/* eslint-disable no-undef */
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'
import './index.scss'
// import { datadogRum } from '@datadog/browser-rum'

Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY)
//
// try {
// 	datadogRum.init({
// 		applicationId: process.env.REACT_APP_DATADOG_KEY,
// 		clientToken: process.env.REACT_APP_DATADOG_TOKEN,
// 		site: 'datadoghq.com',
// 		service: process.env.REACT_APP_DATADOG_SERVICE,
//
// 		// Specify a version number to identify the deployed version of your application in Datadog
// 		version: '1.0.1',
// 		sessionSampleRate: 100,
// 		sessionReplaySampleRate: 100,
// 		trackUserInteractions: true,
// 		trackResources: true,
// 		trackLongTasks: true,
// 		defaultPrivacyLevel: 'mask-user-input',
// 		allowedTracingUrls: [process.env.REACT_APP_MYTABLE_LEMON_API_HOST, process.env.REACT_APP_CARROT_API_HOST, process.env.REACT_APP_PAYMENT_API_HOST]
// 	})
// 	datadogRum.startSessionReplayRecording()
// } catch (e) {
// 	console.log(e)
// }



ReactDOM.render(
	<App />,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
