import React from 'react'
import { ReactComponent as TMLogo } from '../../assets/icon/tablemanager.svg'
import { ReactComponent as ArrowDown } from '../../assets/icon/footerArrowDown.svg'
import './Footer.scss'
import { useState } from 'react'
import { useFetchPolicies } from '../../hooks'
import ModalPortal from '../Share/Modal/ModalPortal'
import PolicyModal from '../Share/Modal/PolicyModal'


export default function Footer() {
	const requiredPolicies = ['SERVICE', 'PRIVACY']
	const [infoOpen, setInfoOpen] = useState(false)
	const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url :'/service/policies', requiredPolicies : requiredPolicies })

	const showServiceTerm = (type) => {
		window.history.pushState(null, null, '')
		openServiceTerm(type)
	}

	return (
		<div className="footer-container">
			<div className="logo-container">
				<TMLogo /> (www.tablemanager.io)
			</div>
			<div className="title-container">
				<div className="footer-title">
					<span>사업자 정보</span>
				</div>
				{infoOpen
					? <ArrowDown className="arrow reverse" onClick={() => setInfoOpen(false)}/>
					: <ArrowDown className="arrow" onClick={() => setInfoOpen(true)}/> }
			</div>
			{infoOpen ?
				<div className="info-container">
					<div>더매니저㈜</div>
					<div>대표 : 안병준 | 대표번호 : 1544-8262 </div>
					<div>서울특별시 강남구 논현로153길 53, 602호(신사동)</div>
					<div>사업자 등록번호 : 751-81-00830 | 통신판매 신고 : 2024-서울강남-02339호</div>
				</div>
				: null}
			<div className="terms-container">
				{!loading && requiredPolicies.map(policyType => {
					const policy = servicePolicies.find(item => item.type === policyType)
					return (
						<span key={policyType} onClick={() => showServiceTerm(policy.type)}>{policy.content.KO.name}</span>
					)
				})}
			</div>
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm}/>
			</ModalPortal>
		</div>
	)
}
