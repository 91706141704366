import React, { useState } from 'react'
import Logo from '../../../../assets/icon/logo_footer.png'
import FooterArrowDown from '../../../../assets/icon/footerarrowdown.png'
import FooterArrowUp from '../../../../assets/icon/footerarrowup.png'
import './MyTableFooter.scss'
import { useFetchPolicies } from '../../../../hooks'
import ModalPortal from '../../../Share/Modal/ModalPortal'
import PolicyModal from '../../../Share/Modal/PolicyModal'

export default function MyTableFooter() {
	const requiredPolicies = ['SERVICE', 'PRIVACY']
	const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies : requiredPolicies })
	const [info, setInfo] = useState(false)

	const moveTableManager = ()=> {
		window.open('http://www.tablemanager.io', '_blank')
	}

	const changeInfoState = (state) => {
		setInfo(state)
	}

	const showServiceTerm = (type) => {
		window.history.pushState(null, null, '')
		openServiceTerm(type)
	}

	return (
		<footer className="footer-container">
			<div className="logo-container" onClick={moveTableManager}>
				<img className="logo" src={Logo} alt=""></img>
				(www.tablemanager.io)
			</div>
			<div className="title-container">
				<div className="footer_title">
					<span>사업자 정보</span>
				</div>
				<div>
					{
						info ?
							<img className="arrow footer-arrow-down" src={FooterArrowDown} onClick={(() => changeInfoState(false))} alt=""></img>
							:
							<img className="arrow footer-arrow-up" src={FooterArrowUp} onClick={(() => changeInfoState(true))} alt=""></img>
					}
				</div>
			</div>
			{
				info ?
					(
						<div className="info-container">
							<div>더매니저㈜</div>
							<div>대표 : 안병준 | 대표번호 : 1544-8262 </div>
							<div>서울특별시 강남구 논현로153길 53, 602호(신사동)</div>
							<div>사업자 등록번호 : 751-81-00830 | 통신판매 신고 : 2024-서울강남-02339호</div>
						</div>
					)
					: <></>
			}
			<div className="terms-container">
				{!loading && requiredPolicies.map((policyType, idx) => {
					const policy = servicePolicies.find(item => item.type === policyType)
					return (
						<span key={idx} onClick={() => showServiceTerm(policy.type)}>{policy.content.KO.name}</span>
					)
				})}
			</div>
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm}/>
			</ModalPortal>
		</footer>
	)
}
