import './Footer.scss'

import ModalPortal from '../../../Share/Modal/ModalPortal'
import PolicyModal from '../../../Share/Modal/PolicyModal'
import React from 'react'
import { ReactComponent as Seperator } from '../../../../assets/icon/seperator.svg'
import { useFetchPolicies } from '../../../../hooks/index'
import { usePartnerContext } from '../../../../context/Common/partner'

export default function Footer() {
	const { partnerName } = usePartnerContext()
	const requiredPolicies = ['SERVICE', 'PRIVACY']
	const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies })

	function openBiz() {
		window.open('http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7518100830')
	}

	const showServiceTerm = (type) => {
		window.history.pushState(null, null, '')
		openServiceTerm(type)
	}

	return (
		<div className="booking-info-footer-container">
			{partnerName === 'shinhan' && <div className='footer-shinhan-sub'>
			레스토랑 예약상품권 서비스는 신한카드의 제휴사인 더매니저(주)에서 제공 및 운영합니다. <b>신한카드는 더예약 서비스 페이지에 연결하는 역할만 하며, 서비스의 제공과 그 책임은 제휴사인 더매니저(주)</b>에 있습니다.
			</div>}
			<div className="footer-description">
				<div>더매니저(주)</div>
				<div>대표이사 : 안병준</div>
				<div>주소 : 서울특별시 강남구 논현로153길 53, 602호(신사동)</div>
				<div>통신판매 신고 : 2024-서울강남-02339호</div>
				<div>사업자번호 : 751-81-00830</div>
				<div>고객센터 : help@tablemanager.team</div>
			</div>
			<div className="footer-button-container">
				<div className="footer-button b2" onClick={openBiz}>사업자번호 확인</div>
				{!loading && requiredPolicies.map(policyType => {
					const policy = servicePolicies.find(item => item.type === policyType)
					return (
						<div key={policyType} className="policy-wrapper">
							<Seperator className="icon"/>
							<div className="footer-button b2" onClick={() => showServiceTerm(policy.type)}>{policy.content.KO.name}</div>
						</div>
					)
				})}
			</div>
			<div className="footer-sub">더매니저(주)는 통신판매 중개자이며 통신판매의 당사자가 아닙니다.<br />관련하여 상품거래 정보 및 거래에 대한 책임을 지지 않습니다.</div>
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm}/>
			</ModalPortal>
		</div>
	)
}
