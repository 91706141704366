import { useEffect, useState } from 'react'

import modalConfig from '../../../configs/partnerModal.json'
import { usePartnerContext } from '../../../context/Common/partner'
export default function ShinhanModal() {
	const { partnerName } = usePartnerContext()
	const containerStyle = modalConfig.modal[partnerName].containerStyle
	const headerStyle = modalConfig.modal[partnerName].headerStyle
	const bodyStyle = modalConfig.modal[partnerName].bodyStyle
	const footerStyle = modalConfig.modal[partnerName].footerStyle
	const okayButtonStyle = modalConfig.modal[partnerName].okayButtonStyle
	const cancelButtonStyle = modalConfig.modal[partnerName].cancelButtonStyle

	const [showPopup, setShowPopup] = useState(!false)

	useEffect(() => {
		const PopUpNotShow = localStorage.getItem('PopUpNotShow')
		const currentTime = Math.floor(new Date().getTime())

		if (!PopUpNotShow)
			setShowPopup(true)
		if (Number(PopUpNotShow) > currentTime)
			setShowPopup(false)
		if (Number(PopUpNotShow) < currentTime)
			setShowPopup(true)
	}, [])

	const closeModal = () => {
		setShowPopup(false)
	}

	const neverShowModal = (expire) => {
		const expireStr = JSON.stringify(expire)
		localStorage.setItem('PopUpNotShow', expireStr)

		closeModal()
	}

	return (
		<div className={showPopup
			? 'openModal modal'
			: 'modal'}>
			{ showPopup ?
				<div className="modal-container" style={containerStyle}>
					<div className="modal-header" style={headerStyle}>
					신한pLay X 더예약
					</div>
					<div className="modal-body" style={bodyStyle}>
					레스토랑 할인 예약권 판매 서비스는 신한카드의 제휴사인 더매니저(주)에서 제공 및 운영합니다. <br /><br />
					신한카드는 더매니저(주) 서비스 페이지에 연결하는 역할만 하며, 서비스의 제공과 그 책임은 제휴사인 더매니저(주)에 있습니다.
					</div>
					<div className="modal-footer" style={footerStyle}>
						<button variant="contained" color="primary" style={cancelButtonStyle} onClick={() => neverShowModal(Date.now() + (1000 * 60 * 60 * 24 * 7))}>
						7일간 보지 않기
						</button>
						<button variant="contained" color="primary" onClick={closeModal} style={okayButtonStyle}>닫기</button>
					</div>
				</div>
				: null}
		</div>
	)
}
